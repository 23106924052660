/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import Header from "./header"
import Helmet from 'react-helmet'
import "./layout.css"

const Footer = styled.footer`
  font-size: 12px;
  padding: 0 10px;
`

export const Container = styled.div`
  min-height: 95vh;
  padding: 3rem 1rem;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Darker+Grotesque|Playfair+Display|Work+Sans:300&display=swap" rel="stylesheet" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
          <Footer>
            © {new Date().getFullYear()}
            {` `}
            <a href="https://www.distantbluesoftware.com">distantbluesoftware</a>
          </Footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
