import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

const StyledLink = styled(GatsbyLink)`
  color: inherit;
  &:hover {
    color: rgba(155,255,255,0.9);
  }
`

export const Link = ({to, children}) => (
  <StyledLink to={to}>
    {children}
  </StyledLink>
)