import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from 'styled-components';

import { FiHome, FiInfo, FiBookOpen, FiEdit2 } from 'react-icons/fi';

const SideNav = styled.aside`
  background: rgba(155,155,255,0.9);
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: ${({active}) => active ? 0 : '-140vw'};
  transition: all 0.4s;
  z-index: 10;
`

const Hamburger = styled.div`
  z-index: 1000;
  cursor: pointer;
  position: fixed;
  top: 15px;
  left: ${({active}) => active ? 'calc(100% - 75px)' : '15px'};
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  &.active{
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:before {
    content: "";
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 3px solid transparent;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    border-radius: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
`

const HamburgerLine = styled.div`
  width: 40px;
  height: 4px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &.active:nth-child(2){
    opacity: 0;
  }
  &.active:nth-child(1) {
    -webkit-transform: translateY(12px);
    -ms-transform: translateY(12px);
    -o-transform: translateY(12px);
    transform: translateY(12px);
  }
  &.active:nth-child(3){
    -webkit-transform: translateY(-12px) rotate(90deg);
    -ms-transform: translateY(-12px) rotate(90deg);
    -o-transform: translateY(-12px) rotate(90deg);
    transform: translateY(-12px) rotate(90deg);
  }
`

const NavContents = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
`

const HeaderLink = styled(Link)`
  font-size: 2em;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    color: rgba(155,255,255,0.9);
  }
  svg {
    stroke-width: 1;
  }
`

const HeaderLinkText = styled.span`
  margin-left: 20px;
`

const Header = ({ siteTitle }) => {
  const [navActive, setNavActive] = useState(false);
  const navClass = navActive ? 'active' : '';
  return (
    <>
      <Hamburger className={navClass} active={navActive} onClick={() => setNavActive(!navActive)}>
        <HamburgerLine className={navClass}/>
        <HamburgerLine className={navClass}/>
        <HamburgerLine className={navClass}/>
      </Hamburger>
      <SideNav active={navActive}>
        <NavContents>
          <HeaderLink to="/" onClick={() => setNavActive(!navActive)}>
            <FiHome />
            <HeaderLinkText>Home</HeaderLinkText>
          </HeaderLink>
          <HeaderLink to="#about" onClick={() => setNavActive(!navActive)}>
            <FiInfo />
            <HeaderLinkText>About</HeaderLinkText>
          </HeaderLink>
          <HeaderLink to="#issue" onClick={() => setNavActive(!navActive)}>
            <FiBookOpen />
            <HeaderLinkText>Read</HeaderLinkText>
          </HeaderLink>
          <HeaderLink to="/submit" onClick={() => setNavActive(!navActive)}>
            <FiEdit2 />
            <HeaderLinkText>Submit</HeaderLinkText>
          </HeaderLink>
        </NavContents>
      </SideNav>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
